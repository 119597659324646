<template>
    <div id="SimulationsInfos">

      <page-loader v-if="loading"></page-loader>

      <template v-if="!loading">
        <div class="simulator-step_header" v-if="!isEdit" style="margin-bottom: 30px !important;">
          <h3 class="simulator-step_title">Ajouter une nouvelle simulation</h3>
          <p class="simulator-step_description">Ajouter le nom et la date de fin prévisionnelle de la simulation:</p>
        </div>

        <div class="giga-fields" style="width: 75%;">
            <div class="col-lg-12">
              <div class="giga-input_field "
              >
                <label class="input-field_label">{{$t('simulation_name')}}</label>
                <div class="input-field_area">
                  <input type="text" v-model="$v.simulation.name.$model" :disabled="isVIEWER">
                </div>
              </div>
            </div>
          <div class="col-lg-12">
            <div class="giga-input_field " v-if="userInfo.clientType === this.USER_TYPE.B2B2B">
              <label class="input-field_label">{{$t('siret_beneficiaire')}}</label>
              <div class="input-field_area">
                <input type="text" v-model="$v.simulation.siret.$model" :class="{ 'field-hasError': !siretIsValid || !siretIsAttachedToCompany }" @input="checkAttachedToCompany" :disabled="isVIEWER">
                <small class="form-text text-danger" v-if="!siretIsValid">
                  {{$t('siret_invalid')}}
                </small>
                <small class="form-text text-danger" v-if="siretIsValid && !siretIsAttachedToCompany">
                  {{$t('siret_not_attached_to_company')}}
                </small>
              </div>
            </div>
          </div>
            <div class="col-lg-12" v-if="userInfo.clientType !== this.USER_TYPE.B2B2B">
              <div class="giga-input_field ">
                <label class="input-field_label">{{$t('estimated_end_date')}}</label>
                <div class="input-field_area">
                  <date-picker
                      :disabled="isVIEWER"
                      id="estimated_end_date"
                      v-model="$v.simulation.deliveryDate.$model"
                      format='D MMMM YYYY'
                      valueType="YYYY-MM-DD"
                      :clearable="false"
                      :editable = "false"
                  >
                    <template slot="icon-calendar">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 3H4C2.34315 3 1 4.34315 1 6V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V6C23 4.34315 21.6569 3 20 3Z" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1 9H23" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 5V1" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5 14H7" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11 14H13" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M17 14H19" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5 18H7" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11 18H13" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M17 18H19" stroke="#132D48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </template>
                  </date-picker>
                </div>
              </div>
            </div>
          <div class="col-lg-6">
            <div class="giga-input_field " v-if="userInfo.clientType === this.USER_TYPE.B2B2B">
              <label class="input-field_label">{{$t('agency')}}</label>

                <div class="input-field_area">
                  <select id="agence" v-model="$v.simulation.agence.$model" class="small-input" @change="onChangeAgence($v.simulation.agence.$model)" :disabled="!this.userInfo.admin && (this.userInfo.role.roleType === this.ROLE_TYPE.ADMIN_AGENCE || this.userInfo.role.roleType === this.ROLE_TYPE.USER)"
                          :class="{ 'field-hasError': $v.simulation.agence.$anyDirty && $v.simulation.agence.$invalid }"
                  >
                    <option v-if="userInfo.admin" :value="-1">AUCUNE AGENCE</option>
                    <option v-for="agence of agences" :key="agence.id" :value="agence">{{agence.raisonSocial}}</option>
                  </select>
                  <div class="validation-field-holder" v-if="$v.simulation.agence.$anyDirty && $v.simulation.agence.$invalid">
                    <small v-if="!$v.simulation.agence.required" class="form-text text-danger">
                      {{$t('required_field')}}
                    </small>
                  </div>
                </div>
            </div>
            </div>

          <div :class="computedUserWidth">
            <div class="giga-input_field ">
              <label class="input-field_label">{{$t('users')}}</label>
                <div class="input-field_area">
                  <multiselect id="user" v-model="$v.simulation.users.$model" open-direction="bottom"
                               :multiple="true"  :options="users ? users : []" label="email" track-by="id" :searchable="false" :closeOnSelect="false" :user-select="true" :disableUser="disableUserByEmail"
                          :class="{ 'field-hasError': $v.simulation.agence.$anyDirty && $v.simulation.users.$invalid }"
                          :disabled="disableSelectUser"
                  >
                    <template slot="caret">
                      <div
                          class="multiselect__select d-flex align-items-center justify-content-center"
                      >
                        <div>
                          <b-icon icon="chevron-down"></b-icon>
                        </div>
                      </div>
                    </template>
                  </multiselect>
                  <div class="validation-field-holder" v-if="$v.simulation.agence.$anyDirty && $v.simulation.users.$invalid">
                    <small v-if="!$v.simulation.users.required" class="form-text text-danger">
                      {{$t('required_field')}}
                    </small>
                  </div>
                </div>
            </div>
          </div>
        </div>

        <SimulationInfoModal></SimulationInfoModal>
      </template>

        <!-- {{simulation}} -->
        <!-- {{simulationForm}} -->



    </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from 'vuex'
import {required, requiredIf} from 'vuelidate/lib/validators';
import SimulationInfoModal from '@/views/Simulations/SimulationInfoModal';
import eventBus from "@/shared/event.bus";
import { pick } from "lodash";
import PageLoader from '../../../components/static/pageLoader.vue'
import {regex} from "vuelidate/lib/validators/common";
import {agenceService} from "@/views/service/agence.service";
import {userService} from "@/views/service/user.service";
import {simulationService} from "@/views/Simulations/services";
import {constants} from "@/shared/constants";
import {helpers} from "@/shared/helper";

export default {
    name: "SimulationInfos",
    components: {SimulationInfoModal, PageLoader},
    validations: {
      simulation: {
        name: {
            required
        },
        deliveryDate: {
          required: requiredIf(function() {
            return !this.fieldsRequiredB2B2B;
          })
        },
        siret: {
          required: requiredIf(function() {
            return this.fieldsRequiredB2B2B;
          }),
          customValidation: function(value) {
            return this.siretIsValid;
          },
          customValidation2: function(value) {
            return this.siretIsAttachedToCompany;
          }
        },
        agence: {
          required: requiredIf(function() {
            return this.fieldsRequiredB2B2B;
          })
        },
        users: {
          required
        },
      }
    },
  async mounted() {
      if(this.simulationType == 'COMPLEX' && !this.isEdit && this.userInfo.clientType !== this.USER_TYPE.B2B2B){
          setTimeout(()=>{
            this.$bvModal.show('simulation-info-modal')
          }
          ,300)
      }
      if (this.simulation.id) {
        const invalid = this.$v.$invalid ;
        eventBus.$emit("invalid-form", invalid);
      } else {
        if(this.userInfo.clientType === this.USER_TYPE.B2B2B){
          this.setSimulation({
            name: '',
            deliveryDate: null,
            desiredReturnDate: null,
            submissionTechnicalOfferDate: null,
            contractingProjectDate: null,
            workStartDate: null,
            siret: '',
            agence: null,
            users: null
          });
        }else {
          this.setSimulation({
            name: '',
            deliveryDate: null,
            users: null
          });
        }
      }
    this.fetchAgences();
    this.fetchUsers();
    if(this.userInfo.clientType === this.USER_TYPE.B2B2B){
      if(this.userInfo.role?.roleType === this.ROLE_TYPE.ADMIN_AGENCE && this.simulation.users == null){
        this.$v.simulation.agence.$model = this.userInfo.agence;
        this.fetchUsersByAgency(this.$v.simulation.agence.$model)
      }
      if(this.userInfo.role?.roleType === this.ROLE_TYPE.USER && this.simulation.users == null){
        this.$v.simulation.agence.$model = this.userInfo.agence;
        const users = await this.fetchUsersByAgency(this.$v.simulation.agence.$model);
        this.$v.simulation.users.$model = Array.of(users.find((user) => user.email === this.userInfo.email));
      }

      setTimeout(()=>{
        if(this.userInfo.admin && this.simulation.users && !this.simulation.agence){
          this.$v.simulation.agence.$model = -1;
          this.$store.dispatch("simulation/fetchUsersByAgency", -1).then(users => {
            this.setUsersAgence(users);
            this.$v.simulation.users.$model = this.simulation.users;
          });
       }
      },400)

    }

  },
    beforeDestroy() {
      eventBus.$emit("save-simulation", pick(this.simulation, ['id', 'name', 'deliveryDate', 'desiredReturnDate','submissionTechnicalOfferDate', 'contractingProjectDate' ,'workStartDate', 'comment' ,'siret', 'users','type', 'status']));
      this.setUsersAgence(null)
    },
    computed:{
          ...mapGetters('simulation', {simulation : 'getSimulation', isEdit: 'isEdit', simulationType: "getSimulationType", users: "getUsers"}),
          ...mapGetters("loader", { loading: "getLoading" }),
          ...mapGetters("account", { userInfo: "getCurrentUser" }),
      fieldsRequiredB2B2B() {
        return this.userInfo.clientType === this.USER_TYPE.B2B2B;
      },
      siretIsValid() {
            if(this.userInfo.clientType === this.USER_TYPE.B2B2B) return this.checkLuhn(this.$v.simulation.siret.$model);
            else return true;
      },
      computedUserWidth(){
            return this.userInfo.clientType === this.USER_TYPE.B2B2B ? 'col-lg-6' : 'col-lg-12'
      },
      disableSelectUser(){
            return (this.isVIEWER && this.isB2BOrEligible)
      },
      isB2BOrEligible(){
        return this.userInfo.clientType === this.USER_TYPE.B2B ||
            this.userInfo.clientType === this.USER_TYPE.BAILLEUR_SOCIAL;
      },
      isVIEWER(){
        return helpers.isVIEWER();
      },
      disableUserByEmail(){
        return  this.userInfo.role?.roleType === this.ROLE_TYPE.USER ? this.userInfo.email : null;
      }
      },
      data(){
          return{
            agences: [],
            siretIsAttachedToCompany: true,
            USER_TYPE: constants.USER_TYPE,
            ROLE_TYPE: constants.ROLE_TYPE,
          }
      },
      methods: {
        ...mapMutations('simulation', ["setSimulation", "setUsersAgence"]),
        ...mapActions('simulation', ['fetchUsersByAgency']),
         checkLuhn(siret) {
          let nDigits = siret?.length;
          let nSum = 0;
          let isSecond = false;

          for (let i = nDigits - 1; i >= 0; i--) {
            let d = parseInt(siret?.charAt(i), 10);

            if (isSecond) {
              d = d * 2;
            }

            nSum += Math.floor(d / 10);
            nSum += d % 10;

            isSecond = !isSecond;
            if (siret?.length !== 14) {
              return false;
            }
          }
          return nSum % 10 === 0;
        },
        checkAttachedToCompany(){
          if(!this.siretIsValid) {
            return;
          }
          if(this.userInfo.clientType === this.USER_TYPE.B2B2B && this.siretIsValid && this.$v.simulation.siret.$model !== "") {
            simulationService.siretAttachedToCompany(this.$v.simulation.siret.$model).then((isValid)=>{
              this.siretIsAttachedToCompany = isValid
            });
          }
        },
        fetchAgences() {
          agenceService.allAgencesList()
              .then(res => {
                this.agences = res;
              })
        },
        async onChangeAgence(agency){
          this.$v.simulation.users.$model = null
          if(agency === -1){
            await this.fetchUsersByAgency(agency);
          }else {
            this.fetchUsersByAgency(agency);
          }
        },
        fetchUsers(){
          if(this.isB2BOrEligible){
            userService.clientUsersByRole()
                .then(users => {
                  this.setUsersAgence(users);
                  if (!this.simulation.id && !this.userInfo.admin && this.isB2BOrEligible){
                    this.$v.simulation.users.$model = Array.of(this.users.find((user) => user.email === this.userInfo.email));
                  }
                })
          }
        }
},
    watch: {
      '$v.$invalid' : function () {
        const invalid = this.$v.$invalid;
        eventBus.$emit("invalid-form", invalid);
      },
    }
}
</script>
<style>
.field-hasError {
  box-shadow: 0px 0px 3px 0px #E90E00 !important;

}
.giga-fields .giga-input_field:last-child {
  margin-bottom: 20px;
}


.select-options{
  max-height: 224px;
  display: none;
}
</style>
